<!-- 学生基础数据 -->
<template>
    <div class="studentData">
        <el-form ref="form" :model="form" inline class="form" id="form" label-width="100px">
            <el-form-item label="关键字">
                <el-input v-model="form.search" class="search" placeholder="请输入身份证号/学员姓名/学员编号/手机号"></el-input>
            </el-form-item>
            <el-form-item label="选择学校">
                <!-- <el-input v-model="form.school" placeholder="请输入"></el-input> -->
                <el-select v-model="form.school" placeholder="请选择" :clearable="true">
                    <el-option v-for="item in form.schoolChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属机构">
                <el-select v-model="form.belonging" placeholder="请选择所属机构" :clearable="true">
                    <el-option v-for="item in form.belongingChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="检索">
                <el-select v-model="form.type" placeholder="请选择" :clearable="true">
                    <el-option v-for="item in form.typeChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="" style="display: flex;justify-content: space-between;">
            <div class="" style="margin-left:22px ;">
                <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                    @click="refresh">
                    <!-- 刷新 -->
                </el-button>
                <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;"
                    @click="newlyAdded">
                    新增
                </el-button>

            </div>
            <div class="" style="margin-right: 10px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="close">
                    重置
                </el-button>
            </div>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="650" id="out-table" @sort-change="abc">
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="username" sortable="custom"  label="学员姓名" align="center" 
            width="120" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="pinyin_name" sortable="custom" label="拼音名" align="center" width="200">
            </el-table-column>
            <el-table-column prop="idcard" sortable="custom" label="身份证号" align="center" width="200">
            </el-table-column>
            <el-table-column prop="mobile1" sortable="custom" label="手机号" align="center" width="150">
            </el-table-column>
            <el-table-column prop="mcode" sortable="custom" label="助记码" align="center" width="150">
            </el-table-column>
            <el-table-column prop="nation" sortable="custom" label="民族" align="center" width="100">
            </el-table-column>
            <el-table-column prop="nation2" sortable="custom" label="国籍" align="center" width="100">
            </el-table-column>
            <el-table-column prop="org_name" label="相关机构" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="head_image" label="相片" align="center" width="100">
                <template slot-scope="scope">
                    <img :src="scope.row.head_image" style="width: 20px;height: 20px;margin-top: 5px;">
                </template>
            </el-table-column>
            <el-table-column prop="add_time" label="录入时间" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column prop="school_name" label="学校-公司" sortable="custom" align="center" width="150">
            </el-table-column>
            <el-table-column prop="grade" label="现年级" sortable="custom" align="center" width="100">
            </el-table-column>
            <el-table-column prop="start_school" label="入学年份" sortable="custom" align="center" width="180">
            </el-table-column>
            <el-table-column prop="parent_name" label="家长姓名" sortable="custom" align="center" width="120">
            </el-table-column>
            <el-table-column prop="add_aid" label="录入人" sortable="custom" align="center" width="100">
            </el-table-column>
            <el-table-column prop="save_aid" label="修改人" sortable="custom" align="center" width="100">
            </el-table-column>
            <el-table-column prop="save_time" label="最近调整时间" sortable="custom" align="center" width="200">
            </el-table-column>
            <el-table-column  label="操作" align="center" fixed="right" width="200">
                <template slot-scope="scope">
                    <el-button icon="el-icon-edit" size="mini" style="background: #18BC9C;color:#FFFFFF;"
                        @click="edit(scope.row.id)">
                        编辑
                    </el-button>
                    <!-- <el-button size="mini" class="daochu">
                        导出相片
                    </el-button> -->
                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="delet(scope.$index, scope.row.id)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50, 100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    number: "", //学员编号
                    name: "", //学员姓名
                    school: "", //学校
                    search:"",//关键字
                    schoolChoose: [
                        {label: "入学",value: 1},
                        {label: "结业",value: 2},
                        {label: "未入学",value: 3}
                    ], //学校选择
                    belonging: "", //所属机构
                    belongingChoose: [{
                            label: "培训",
                            value:1
                        },
                        {
                            label: "考试",
                            value: 2
                        },
                        {
                            label: "两者",
                            value: 3
                        }
                    ], //所属机构选择
                    type:0,//检索
                    typeChoose:[
                      {value:0,label: "无"},
                      {value:1,label: "无性别"},
                      {value:2,label: "无照片"},
                      {value:3,label: "无国籍"},
                      {value:4,label: "无身份证号"},
                      {value:5,label: "出生日期异常"},
                      {value:6,label: "拼音异常"},
                      {value:7,label:"身份证重复"},
                      {value:8,label:"非大陆身份证"},
                      {value:9,label:"身份证异常"},
                    ]
                },
                tableData: [],
                currentPage: 1,
                currentLimit: 20,
                total: 0,
                arr:[]
            }
        },
        created() {
            this.getList()
            // this.refresh()
        },
        activated() {
            this.getList()
        },
        methods: {
            getList() { //获取数据
                this.$request({
                    url: '/api/student/list',
                    method: 'POST',
                    data: {
                        key:this.form.search,//关键字
                        school_name: this.form.school,//学校名称
                        mechanism_type: this.form.belonging,//所属机构
                        type:this.form.type,//检索
                        page: this.currentPage,
                        limit: this.currentLimit,
                        sort:this.arr
                    }
                }).then(res => {
                    console.log(res)
                    this.tableData = res.data.list
                    this.total = res.data.count
                })
            },
            handleSizeChange(val) {
                this.currentLimit = val
                this.getList()
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.getList()
            },
            // 查询
            query() {
                this.currentPage=1
            	this.getList()
            },
            // 重置
            close() {
                this.form.search=''//关键字
                this.form.school=''//学校名称
                this.form.belonging=''//所属机构
                this.form.type=0//检索
                this.getList()
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            newlyAdded() { //新增
                this.$router.push({
                    path: "addStudentData"
                })
            },
            edit(id) { //编辑
                this.$router.push({
                    path: "editStudentData",
                    query: {
                        id: id
                    }
                })
            },
            delet(index,id) {
                this.$confirm('是否删除此条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$request({
                        url: '/api/student/del',
                        method: 'POST',
                        data: {
                            id:id
                        }
                    }).then(res => {
                        if (res.code == 1) {
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.tableData.splice(index, 1)
                            this.getList()
                        }
                    })
                })

            },
            // 姓名排序
            fn2(a, b){
                return a.username.localeCompare(b.username)
            },
            //排序
            abc(i){ 
                console.log(i)
                this.tableData=[]
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                this.getList()
            }
        }
    }
</script>

<style scoped="scoped">
    .studentData {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .form {
        padding-top: 34px;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    .form ::v-deep .search .el-input__inner{
        width: 300px;
        height: 37px;
    }
    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }

    .form ::v-deep .el-form-item__label {
        color: #000000;
    }

    /* 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }

    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }

    ::v-deep .el-icon-download {
        font-weight: bold !important;
    }

    .daochu {
        padding-left: 30px;
        background: url(../../assets/export.png) 8px 5px no-repeat #FF7300;
        color: #FFFFFF;
    }

    /* 表格 */
    .el-table {
        margin: 20px;
    }

    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 28px;
    }

    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
